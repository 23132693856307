import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/mag-hd-c-profile.png"
import UsaBackground from "../../components/individualProductPage/images/handmade-in-usa-banner-mag-hd.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/mag-hd-machineoverview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/heavy-duty-banner-mag-hd.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/mag-hd-dims.jpg"

//Thumbnail Images
import CylindricalThumb1Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-1.jpg"
import CylindricalThumb2Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-4.jpg"
import CylindricalThumb3Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-3.jpg"
import CylindricalThumb4Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-2.jpg"
import CylindricalThumb5Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-5.jpg"
import DiskThumb1 from "../../components/individualProductPage/images/mag-hd-30d-360-view-1-thumb.jpg"
import DiskThumb2 from "../../components/individualProductPage/images/mag-hd-30d-360-view-4-thumb.jpg"
import DiskThumb3 from "../../components/individualProductPage/images/mag-hd-30d-360-view-3-thumb.jpg"
import DiskThumb4 from "../../components/individualProductPage/images/mag-hd-30d-360-view-2-thumb.jpg"
import DiskThumb5 from "../../components/individualProductPage/images/mag-hd-30d-360-view-5-thumb.jpg"
//End Thumbnail Images
import '../product-page-styles.scss';

//Main Images
import CylindricalMain1Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-1.jpg"
import CylindricalMain2Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-4.jpg"
import CylindricalMain3Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-3.jpg"
import CylindricalMain4Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-2.jpg"
import CylindricalMain5Image from "../../components/individualProductPage/images/mag-hd-29c-360-view-5.jpg"
import DiskMain1Image from "../../components/individualProductPage/images/mag-hd-30d-360-view-1.jpg"
import DiskMain2Image from "../../components/individualProductPage/images/mag-hd-30d-360-view-4.jpg"
import DiskMain3Image from "../../components/individualProductPage/images/mag-hd-30d-360-view-3.jpg"
import DiskMain4Image from "../../components/individualProductPage/images/mag-hd-30d-360-view-2.jpg"
import DiskMain5Image from "../../components/individualProductPage/images/mag-hd-30d-360-view-5.jpg"
import OrbitalThumb1 from "../../components/individualProductPage/images/maghd-v2.0-1.jpg"
import OrbitalThumb2 from "../../components/individualProductPage/images/maghd-v2.0-2.jpg"
import OrbitalThumb3 from "../../components/individualProductPage/images/maghd-v2.0-3.jpg"
import OrbitalThumb4 from "../../components/individualProductPage/images/maghd-v2.0-4.jpg"
import OrbitalThumb5 from "../../components/individualProductPage/images/maghd-v2.0-5.jpg"
import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"

import { OptionsBar } from "../../components/individualProductPage/FeaturedProduct/FeaturedProductStyles/OptionsBar"
//End Main Images

import MiniHdOptions from "../../components/individualProductPage/MiniHdOptions"
import ControllerImage from "../../images/choose-your-controller-v2.jpg"
import SEO from "../../components/SEO"
import Head from "../../components/Head"
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const SizeBtnContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const MediaTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 650px;
  }
`
const ControllerImageContainer = styled.div`
  max-width: 1400px;
  margin: 8rem auto;
  text-align: center;
  color: red;

  h1 {
    margin: 3rem 0;
    font-size: 3em;
  }

  img {
    width: 100%;
    height: auto;
  }
`

const MagHDProductPage = props => {
  return (
    <>
      <SEO title="Mag-HD" />
      <Head title="Mag-HD" />
      <Layout>
        <IndividualMachine
          cylindricalThumb1={CylindricalThumb1Image}
          cylindricalThumb2={CylindricalThumb2Image}
          cylindricalThumb3={CylindricalThumb3Image}
          cylindricalThumb4={CylindricalThumb4Image}
          cylindricalThumb5={CylindricalThumb5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskThumb1}
          diskThumb2={DiskThumb2}
          diskThumb3={DiskThumb3}
          diskThumb4={DiskThumb4}
          diskThumb5={DiskThumb5}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain5Image}
          orbitalThumb1={OrbitalThumb1}
          orbitalThumb2={OrbitalThumb2}
          orbitalThumb3={OrbitalThumb3}
          orbitalThumb4={OrbitalThumb4}
          orbitalThumb5={OrbitalThumb5}
          orbitalMain1={OrbitalThumb1}
          orbitalMain2={OrbitalThumb2}
          orbitalMain3={OrbitalThumb3}
          orbitalMain4={OrbitalThumb4}
          orbitalMain5={OrbitalThumb5}
          brochureLink="/FC-V2-WALK-BEHIND-SM.pdf"
          techLink="/FC-V2-WALK-BEHIND-specs.pdf"
          partsLink="https://www.dropbox.com/s/hbsdmxvaqcb0ahr/FC-MAG-HD-v2-P-EN.pdf?dl=0"
          opLink="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0"
          title="Mag-HD Walk Behind Floor Scrubber"
          textContent="Cleaning large area is what the Mag-HD floor scrubber is engineered and built to do. With it's high-capacity solution and recovery tanks and battery pack options, the Mag-HD offers extended scrub times and covers a lot of square footage per hour.  Equipped with scrub motors more powerful than others, and wide scrub path options, the Mag-HD will clean your floors better, and faster. Well balanced and very stable this machine is operator friendly and a pleasure to operate. "
          pageUrl=""
          specifications="Cleaning Path: 29-34 | Run Time: Up To 5.0 Hours | Tank Capacity: Sol: 35 Gal, Rec: 37 Gal"
          videoSource="https://www.youtube.com/embed/NaGoDJxcSGM"
          upLink="/walk-behind-floor-scrubbers/micromag"
          machineName="Mag-HD"
          downLink="/walk-behind-floor-scrubbers/mini-hd"
          pmLink="/Walk-Behind-V2-PM.pdf"
          upLink="/ride-on-floor-scrubbers/pilot"
          mtLink="https://www.dropbox.com/s/sx03160k2ppabfl/daily-weekly-maintenance-premium-v21306.pdf?dl=0"
        />
        <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Scrubbers"
            link="/v2-you-be-the-judge.pdf"
          />

          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Scrubbers"
            link="/videos"
          />
          <MediaTile
            link="/images#walkie-scrubbers"
            title="Images"
            subTitle="Walk Behind Floor Scrubbers"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Scrubbers"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="Mag-HD"
          category="Walk Behind Floor Scrubber"
          path='29 - 34"'
          path2="(73.7 - 83.8 cm)"
          runTime="Up To 5.0 Hours"
          solution="Solution: 35 Gal"
          solutionLiter="(132.5L)"
          recovery="Recovery: 37 Gal"
          recoveryLiter="(140.1L)"
        />
        <ControllerImageContainer>
          <h1>Choose Your Controller</h1>
          <img src={ControllerImage} alt="" />
        </ControllerImageContainer>
        <MadeInUSA bgImage={UsaBackground} />
        <div className="spec-title-wrapper">
        <SectionTitle title="Machine Overview" />
        </div>
        <MachineOverview image={MachineOverviewImage} />
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='55" x 26" x 40'
          weightTitle="Weight (w/o batteries):"
          weight="441 - 481 lbs"
          voltageTitle="System Voltage:"
          voltage="24 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="315 ah WET"
          powerTitle="Power:"
          power="0.75hp"
          tankTitle="Tank Material:"
          tank='Poly 3/8"'
        />
        <OptionsBar>Options</OptionsBar>
        <MiniHdOptions />

        <div id="demo-form"></div>
        <div className="spec-title-wrapper">
        <SectionTitle title="Request a Demo" />
        </div>
        <RequestDemoForm image={CylindricalMain1Image} />
      </Layout>
    </>
  )
}

export default MagHDProductPage
